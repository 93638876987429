<template>
	<Layout>
		<PageHeader :title="PageHeaderT" :items="PageHeaderItems" />
		<div class="block_content d-flex flex-column">
			
		</div>
	</Layout>
</template>

<script>
export default {
	data() {
		return {
			/* 面包屑 */
			PageHeaderT: this.$t('dashboard.title'),
			PageHeaderItems: [
				{
					text: this.$t('dashboard.title'),
					active: true,
					href: '/dashboard'
				}
			],
		};
	},
	methods: {},
	components: {}
};
</script>

<style scoped lang="less"></style>
